<template>
  <div :class="classes" @click="$emit('click')">
    <video
      v-if="isVideo"
      :class="$style.main"
      playsinline
      webkit-playsinline
      muted
      loop
      autoplay
      :src="url"
    />
    <img
      v-else-if="url"
      :class="$style.main"
      :src="url"
      alt=""
      @error="handleError"
    />
    <img v-if="sticker" :class="$style.sticker" :src="stickerUrl" alt="" />
    <span v-if="border" :class="$style.border" :style="borderStyle"></span>
  </div>
</template>

<script>
import { createColor } from '@/utils/color'
import defaultImage from './avatar.svg?url'

export default {
  name: 'UiAvatar',
  props: {
    src: {
      type: Object,
      default: null
    },
    border: {
      type: [String, Array],
      default: null
    },
    sticker: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: 'medium',
      validator: v =>
        ['small', 'medium', 'large', 'xlarge', 'xxlarge', 'custom'].includes(v)
    }
  },
  data() {
    return {
      brokenUrl: false,
      defaultImage
    }
  },
  emits: ['click'],
  computed: {
    isVideo() {
      return this.src?.ext === 'mp4'
    },
    classes() {
      return [
        this.$style.avatar,
        [this.$style[this.size]],
        [(this.sticker || this.border) && this.$style.relative],
        [this.border && this.$style.rounded]
      ]
    },
    stickerUrl() {
      if (this.sticker) {
        return this.sticker[this.size === 'small' ? 100 : 300]
      } else {
        return ''
      }
    },
    borderStyle() {
      return {
        background: createColor(this.border)
      }
    },
    url() {
      if (this.src) {
        if (['small', 'medium'].includes(this.size)) {
          return this.src.small
        } else {
          return this.src.large
        }
      } else {
        return this.defaultImage.toString()
      }
    }
  },
  methods: {
    handleError() {
      this.brokenUrl = true
    }
  }
}
</script>

<style lang="scss" module>
.avatar {
  border-radius: 50%;
  flex-shrink: 0;
  .main {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
    z-index: 2;
    background: var(--t-app-bg);
  }
}
.avatar.relative {
  position: relative;
}
.rounded {
  padding: 0.2em;
  background: var(--t-app-l2-bg);
}
.border {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  z-index: 1;
}
.sticker {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  width: 35%;
  height: 35%;
}
.small {
  width: 2.4em;
  height: 2.4em;
  &.rounded {
    padding: 0.2em;
  }
}
.medium {
  width: 3.4em;
  height: 3.4em;
  &.rounded {
    padding: 0.2em;
  }
}
.large {
  width: 4em;
  height: 4em;
  &.rounded {
    padding: 0.2em;
  }
}
.xlarge {
  width: 10.6em;
  height: 10.6em;
  @include down(sm) {
    width: 8em;
    height: 8em;
  }
  &.rounded {
    padding: 0.3em;
  }
  .sticker {
    width: 30%;
    height: 30%;
  }
}
.xxlarge {
  width: 12.8em;
  height: 12.8em;
  @include down(sm) {
    width: 8.4em;
    height: 8.4em;
  }
  &.rounded {
    padding: 0.7em;
    @include down(sm) {
      padding: 0.6em;
    }
  }
  .sticker {
    width: 30%;
    height: 30%;
  }
}

.custom {
  --width: 5em;
  --rounded-padding: 0.2em;
  width: var(--width);
  height: var(--width);

  &.rounded {
    padding: var(--rounded-padding);
  }
}
</style>
